import { useState } from 'react'
import { Col, Row, Button, Typography, Alert, Divider, Grid } from 'antd'
import LogoSync2 from './assets/LogoSync2'
import Logo from './assets/Logo'
import { CheckOutlined as IconEnable } from '@ant-design/icons'
import openLiteWallet from './openLiteWallet'

const { useBreakpoint } = Grid
const { Text, Paragraph, Link } = Typography
const SPONSOR_URL = 'https://sponsor.vechain.energy/by/42'

function App () {
  const [configClicked, setConfigClicked] = useState()
  const [showSyncLiteInfo, setShowSyncLiteInfo] = useState()
  const sponsorUrl = SPONSOR_URL
  const feeDelegationConfig = { selfSignOnFailure: true, defaultDelegator: { url: sponsorUrl } }
  const configUrl = `fee-delegation-setting?config=${JSON.stringify(feeDelegationConfig)}`

  const screens = useBreakpoint()

  const handleConfigClicked = () => {
    setConfigClicked(true)
    setTimeout(() => setShowSyncLiteInfo(true), 2500)
  }
  const resetConfigClicked = () => {
    setConfigClicked(false)
    setShowSyncLiteInfo(false)
  }

  const handleOpenLiteWallet = () => {
    openLiteWallet(configUrl)
  }

  return (
    <Row gutter={[16, 32]}>
      <Col span={24}>
        <br />
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 14, offset: 5 }} xl={{ span: 12, offset: 6 }} align='center'>
            <Paragraph>
              <Text strong style={{ fontSize: '1rem' }}>You</Text> flip the switch. <Text strong style={{ fontSize: '1rem' }}>We</Text> pay for your transactions<br />
              <Text strong style={{ fontSize: '1rem' }}>Because</Text> we want you to know us.<br />
              <Text strong style={{ fontSize: '1rem' }}>Enjoy</Text> the ease of VeChains Blockhain at the same time.
            </Paragraph>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 14, offset: 5 }} xl={{ span: 12, offset: 6 }} align='center'>
            <Link href='https://vechain.energy' target='_blank' rel='noopener noreferrer'><Logo size={72} /></Link><br />
            <Text>We are <Link href='https://vechain.energy' target='_blank' rel='noopener noreferrer'>vechain.energy</Link>, an API-service for Development(-Teams) that removes web3-headaches from everyone involved.</Text>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 14, offset: 5 }} xl={{ span: 12, offset: 6 }} align='center' />
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 14, offset: 5 }} xl={{ span: 12, offset: 6 }} align='center'>
            <Link href={`connex:${configUrl}`} onClick={handleConfigClicked}>
              <Row gutter={[16, 16]}>
                <Col span={24}><Button block type='primary' size='large' shape='round' icon={<IconEnable />} disabled={configClicked || configClicked}>enable free transactions{screens.sm ? ' using fee delegation now' : ''}</Button></Col>
              </Row>
            </Link>
          </Col>
        </Row>
      </Col>

      {showSyncLiteInfo && (
        <Col xs={{ span: 24, offset: 0 }} xl={{ span: 12, offset: 6 }}>
          <Alert
            type='info'
            message='Sync2 did not open?'
            description={<>Ensure Sync2 is installed. Allow your Browser to open it. If you are using Sync2-lite as web-application <Link href='#' onClick={handleOpenLiteWallet}>configure Sync2-lite</Link></>}
            onClose={resetConfigClicked}
            closable
          />
        </Col>
      )}

      <Col span={24}>
        <Divider plain>Official Sync2 Wallet required</Divider>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 14, offset: 5 }} xl={{ span: 12, offset: 6 }} align='center'>
            <Link href='https://sync.vecha.in/' target='_blank' rel='noopener noreferrer'>
              <Row gutter={[16, 0]}>
                <Col span={24}><LogoSync2 size={32} /></Col>
                <Col span={24}><Button type='link' size='small'>Download Sync2</Button></Col>
              </Row>
            </Link>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Divider plain>How it works</Divider>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 14, offset: 5 }} xl={{ span: 12, offset: 6 }} align='center'>
            <Text italic style={{ fontSize: '0.75rem' }} type='secondary'>
              Enabling fee delegation will open Sync2 Wallet with a configuration preset for the given link.<br />
              The preset is shown and requires your permission to be applied, no change will be applied by clicking on the link above.<br />
              During fee delegation the unsigned transaction is shared with the delegator to receive a payer signature.<br />
              Your wallet adds payer- and wallet signature, instructing the blockchain to charge the delegator for fees.
            </Text>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Divider plain>Criteria for Sponsorships</Divider>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 14, offset: 5 }} xl={{ span: 12, offset: 6 }} align='center'>
            <Text italic style={{ fontSize: '0.75rem' }} type='secondary'>
              At first no restrictions are applied.<br />
              All transactions are paid. Constraints might be applied in the future to ensure the Sponsorship pool benefits new users the most.
            </Text>
          </Col>
        </Row>
      </Col>

    </Row>
  )
}

export default App
